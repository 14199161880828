import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_SHOP_PAGES } from 'Consts/routes';
import { SHOP_PAGE_LAG_TYPES, SHOP_PAGE_TYPES } from 'Consts/shopPage';

import { fromSelectObject, filterKeys } from 'Utils/object';
import { parseQueryToObject } from 'Utils/querystring';
import { getShopLinks } from 'Utils/shopPage';

import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layout/panel/ElementEditor';

export default class AdminShopPageEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            update: PropTypes.func.isRequired,
            create: PropTypes.func.isRequired,
            remove: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
    };

    state = {
        formState: {},
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        const { data } = this.props;

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...this.dataToFormState(data || {}),              
            },
        }));
    }

    dataToFormState = data => {
        return {
            ...data,
        };
    }

    formStateToData = formState => {
        return {
            ...formState,
            lagType: fromSelectObject(formState.lagType),
            shopType: fromSelectObject(formState.shopType),
        };
    }

    onSubmit = formState => {
        const { data } = this.props;

        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onUpdate = formState => {
        const { actions, data } = this.props;

        return actions.update({
            ...this.formStateToData(formState),
            id: data.id,
        });
    }

    onCreate = formState => {
        const { actions, history } = this.props;

        return actions.create({
            ...this.formStateToData(formState),
        }).then(() => {
            history.push(ADMIN_SHOP_PAGES.path);
        });
    }

    render() {
        const { location, history, data, actions } = this.props;
        const { formState } = this.state;

        let shopLinks;
        if (data && data.id) {
            shopLinks = getShopLinks(data);
        }

        return (
            <StyledComponent
                className="admin-shop-page-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    details={shopLinks}
                    // controls={[{
                    //     visible: Boolean(data),
                    //     title: 'Usuń sklep',
                    //     subtitle: 'Sklep zostanie usunięty',
                    //     buttonProps: {
                    //         onClick: () => actions.remove({ id: data && data.id }).then(() => {
                    //             return history.push(ADMIN_SHOP_PAGES.path);
                    //         }),
                    //         children: 'Usuń',
                    //     },
                    // }]}
                    forms={[{
                        title: 'Dane',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'input',
                            name: 'name',
                            label: 'Nazwa',
                        }, {
                            isVisible: Boolean(!data),
                            type: 'input',
                            name: 'slug',
                            label: 'slug',
                        }, {
                            type: 'input',
                            name: 'lagValue',
                            label: 'Wartość laga',
                            inputProps: {
                                type: 'number',
                            },
                        }, {
                            type: 'select',
                            name: 'lagType',
                            label: 'Typ wartości laga',
                            options: SHOP_PAGE_LAG_TYPES.map(durationType => ({
                                value: durationType.key,
                                label: durationType.label,
                            })),
                        }, {
                            type: 'select',
                            name: 'shopType',
                            label: 'Typ sklepu',
                            options: SHOP_PAGE_TYPES.map(durationType => ({
                                value: durationType.key,
                                label: durationType.label,
                            })),
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}