import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ACTIVITY_LOGS_DATA_TYPE_SHOP_PAGE } from 'Consts/activityLogs';
import { ADMIN_SHOP_PAGES } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import Page from 'Components/layout/panel/Page';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Editor from 'Components/admin/shopPages/Editor';
import LogsList from 'Components/admin/activityLogs/List';

export const TAB_DATA = 'data';
export const TAB_LOGS = 'logs';

export default class AdminUserShopPageManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        shopPage: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };
    static defaultProps = {};
    state = {};

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.single({ id: params.id });
    }

    render() {
        const { location, history, shopPage } = this.props;

        return (
            <StyledComponent
                className="admin-shop-page-manage"
                styles={require('./styles')}
            >
                <Page
                    elementMode={true}
                    data={shopPage}
                >
                    {shopPage && shopPage.data && (
                        <PageContent
                            breadcrumbs={[{
                                to: withVariables(ADMIN_SHOP_PAGES.path),
                                label: 'Lista sklepów',
                            }]}
                        >
                            <TabNavigation
                                location={location}
                                history={history}
                                headline="Edytuj sklep"
                                tabs={[{
                                    key: TAB_DATA,
                                    label: 'Dane',
                                    children: (
                                        <Editor
                                            location={location}
                                            history={history}
                                            data={shopPage.data}
                                        />
                                    ),
                                }, {
                                    visible: true,
                                    key: TAB_LOGS,
                                    label: 'Logi',
                                    children: (
                                        <LogsList 
                                            location={location}
                                            history={history}
                                            predefinedQuery={{
                                                dataType: ACTIVITY_LOGS_DATA_TYPE_SHOP_PAGE,
                                                dataId: shopPage.data.id || undefined,
                                            }}
                                            onMapFilters={() => []}
                                        />
                                    ),
                                }]}
                            />
                        </PageContent>
                    )}
                </Page>
            </StyledComponent>
        );
    }
}